import React, { useState, useEffect } from "react"
import Layout from "~components/layout"
import SEO from "~components/seo"
import { graphql } from "gatsby"
import RichText from "~components/richText"

import styled from "styled-components"
import theme from "~theme/theme"
import useBreakpoint from '~utils/use-breakpoint'

import { ArticleWrapper, ArticleList } from "../theme/common"
import Article from "~components/Article"
import Sidebar from "~components/Sidebar"
import ScrollLink from "~components/HomepageScrollLink"

import useSiteSettings from "~utils/useSiteSettings"

const IndexPage = ({ data, location }) => {
  const { sm } = useBreakpoint()
  const { editorial } = useSiteSettings()
  if(!data) return null
  // const article = data.prismic.allArticles.edges[0].node
  // const editorialText = data.prismic.allEditorials.edges[0].node.text
  // const siteMeta = data.prismic.allWebsites.edges[0].node

  const a = data.allSanityArticle.nodes[0]

  return (
    <Layout>
      <SEO title="Home" image={null} description={null} />
      <HomeIntroWrapper>
        <TwoCol>
          <RichText content={editorial} />
        </TwoCol>
      </HomeIntroWrapper>
      <ArticleWrapper id="article">
        <ArticleList>
          <Sidebar location={location} />
        </ArticleList>
        {sm &&
          <Article
            id={a.id}
            title={a.title}
            slug={a.slug}
            content={a._rawContent}
            location={location}
          />
        }
      </ArticleWrapper>
    </Layout>
  )
}

export const articleQuery = graphql`
{
  allSanityArticle(limit: 1, sort: {order: DESC, fields: content___date}) {
    nodes {
      title
      _type
      id
      slug {
        current
      }
      _rawContent(resolveReferences: {maxDepth: 5})
    }
  }
}

`

const HomeIntroWrapper  = styled.div`
  position: relative;
  border-bottom: 1px solid;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0 1rem;
`

const TwoCol  = styled.div`
  padding-bottom: 20px;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    columns: 2;
    column-gap: 1rem;
  }
  p {
    display: inline-block;
    font-size: ${theme.typeSizes.medium};
  }
`

export default IndexPage
