import React, { useState, useEffect } from "react"
import styled from "styled-components"
import theme from "~theme/theme"

const HomepageScrollLink = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const scroll = () => setScroll(window.scrollY)
    scroll()

    window.addEventListener("scroll", scroll)
    return () => {
      window.removeEventListener("scroll", scroll)
    }
  }, [])

  return (
    <ScrollLink href="#article" scroll={scroll} className="medium">
      Scroll
    </ScrollLink>
  )
}


const ScrollLink = styled.a`
  display: block;
  opacity: ${({ scroll }) => scroll > 0 ? '0' : '1'};
  position: sticky;
  bottom: 0px;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  text-align: right;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    color: ${theme.colours.highlight};
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`

export default HomepageScrollLink
